.imgimgg{
    transition: transform .5s, filter 1.5s ease-in-out;
}

.imgimgg:hover {
  transform: scale(1.1);
  overflow: hidden;
  border-radius: 90px;
  cursor: pointer;
}

 .Popupp{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
} 

.Popupp :is(img){
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    max-width: 95%;
    max-height: 95%;
    padding-top: 80px;
    object-fit: contain;
    z-index: 100;
} 

.Popupp span{
    position: absolute;
    top: 60px;
    right: 50px;
    font-size: 50px;
    font-weight: bolder;
    z-index: 100;
    cursor: pointer;
    color: white;

}